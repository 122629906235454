class Curseur {
    constructor(options) {
        if (Curseur.instance) {
            throw new Error("Curseur already has an instance!");
        }

        // Allow only once instance of curseur
        Curseur.instance = this;

        this.mousePosition = { y: 0, x: 0 };

        // Css
        this.root = document.querySelector(":root");
        this.rootStyle = getComputedStyle(this.root);

        // Default options from new instance
        this.options = options;
        this.defaultOptions = {
            color: "#f28b4c",
            size: "8px",
        };

        // Matchmedia for devices with mouse and min-width
        this.matchMedia = "(pointer:fine) and (min-width: 992px)";
        this.animations = {};

        document.addEventListener("DOMContentLoaded", () => {
            this.init();
        });
    }

    // Function to hide elements to replace with Curseur
    hideShowDomElement() {
        if (!this.options.makeElementsInvisible) {
            return;
        }

        const allElement = document.querySelectorAll(
            this.options.makeElementsInvisible.toString()
        );

        return {
            hide() {
                for (const element of allElement) {
                    element.style.visibility = "hidden";
                }
            },

            show() {
                for (const element of allElement) {
                    element.style.visibility = "initial";
                }
            },
        };
    }

    init() {
        // Create Cursor based on DOMElement: .curseur
        this.createCursor();
        this.mediaQuery();
    }

    mediaQuery() {
        if (window.matchMedia(this.matchMedia).matches) {
            this.active();
        } else {
            this.destroy();
        }

        window
            .matchMedia(this.matchMedia)
            .addEventListener("change", (event) => {
                if (event.matches) {
                    this.active();
                } else {
                    this.destroy();
                }
            });
    }

    destroy() {
        if (this.cursor) {
            document.documentElement.classList.remove("noCursor");
        }

        if (this.controller) {
            this.controller.abort();
        }

        this.hideShowDomElement().show();
    }

    // Used to generate new animation
    createAnimation(DomElement) {
        this.element = DomElement;
        this.name = DomElement.getAttribute("curseur-name");
        this.isActive = false;
        this.activeClass = "is-active";
        this.controller = new AbortController();

        this.toggleVisibility = (visibility) => {
            this.isActive = visibility ? this.active() : this.remove();
        };

        this.active = () => {
            this.element.classList.add(this.activeClass);
            return true;
        };

        this.remove = () => {
            this.element.classList.remove(this.activeClass);
            return false;
        };
    }

    animationExcists(name) {
        const result = Object.keys(this.animations).filter((key) => {
            return this.animations[key].name === name;
        });

        return result.length > 0 ? true : false;
    }

    observeAnimations(elementToAnimate) {
        const animationName = elementToAnimate.getAttribute("data-curseur");
        const selectedAnimation =
            elementToAnimate.getAttribute("data-curseur").value;

        if (!this.animationExcists(animationName)) {
            console.error(`${animationName} does not exists`);
            return;
        }

        let mouseEnter = () => {
            elementToAnimate.addEventListener("mouseenter", (event) => {
                this.defaultCursor.toggle();
                this.animations[animationName].toggleVisibility(true);
            });
        };

        let mouseOut = () => {
            elementToAnimate.addEventListener("mouseout", (event) => {
                if (!elementToAnimate.contains(event.relatedTarget)) {
                    this.defaultCursor.toggle();
                    this.animations[animationName].toggleVisibility(false);
                }
            });
        };

        mouseEnter();
        mouseOut();
    }

    toggleCursor() {
        this.defaultCursor.toggle();
    }

    active() {
        // Add controller for eventListener
        this.controller = new AbortController();

        this.createCursor();
        this.hideShowDomElement().hide();

        document.documentElement.classList.add(
            this.options.removeCursor ? "noCursor" : null
        );

        this.elementsToAnimate = document.querySelectorAll("[data-curseur]");
        this.animationObjects = document.querySelectorAll("[curseur-name]");

        for (const animationElement of this.animationObjects) {
            this.animations[animationElement.getAttribute("curseur-name")] =
                new this.createAnimation(animationElement);
        }

        for (const element of this.elementsToAnimate) {
            this.observeAnimations(element);
        }
        this.observeMousePosition(this.updateCursorPosition.bind(this));
    }

    createCursor() {
        // Check if instance excists
        if (this.cursor) {
            return;
        }

        // Create new cursor
        this.cursor = document.querySelector(".curseur");
        this.innerCursor = this.createFollowCursor().element;
        this.defaultCursor = this.createDefaultCursor();

        if (this.cursor) {
            // Add default cursor
            this.cursor.insertAdjacentElement(
                "afterbegin",
                this.defaultCursor.element
            );

            document.addEventListener("mousedown", () => {
                this.defaultCursor.element.classList.toggle("is-clicked");
            });
            document.addEventListener("mouseup", () => {
                this.defaultCursor.element.classList.toggle("is-clicked");
            });
            // Add inner cursor
            // document.body.insertAdjacentElement("beforeend", innerCursor);
        }
    }

    setCssVariable(key, value) {
        this.root.style.setProperty(key, value);
    }

    createDefaultCursor() {
        this.setCssVariable(
            "--curseur-height",
            this.options.size || this.defaultOptions.size
        );

        this.setCssVariable(
            "--curseur-width",
            this.options.size || this.defaultOptions.size
        );

        this.setCssVariable("--curseur-backgroundColor", this.options.color);

        const cursor = document.createElement("div");
        cursor.classList.add("defaultCurseur");
        cursor.classList.add("is-active");
        cursor.style.width = "var(--curseur-width)";
        cursor.style.height = "var(--curseur-height)";
        cursor.style.backgroundColor = "var(--curseur-backgroundColor)";
        cursor.style.position = "absolute";
        cursor.style.left = parseInt(cursor.style.height) / 2;
        cursor.style.top = parseInt(cursor.style.height) / 2;

        // setCssVariable("curseur-cursor-width", this.options.size )

        return {
            element: cursor,
            splitSize: parseInt(cursor.style.height) / 2,
            isActive: true,
            toggle: () => {
                cursor.classList.toggle("is-active");
            },
        };
    }

    addHoverClass(...arr) {
        const allHoverElement = [];
        for (let el of arr) {
            allHoverElement.push(...document.querySelectorAll(el));
        }

        allHoverElement.forEach((el) => {
            el.addEventListener("mouseover", () => {
                this.defaultCursor.element.classList.add("is-hovered");
            });

            el.addEventListener("mouseout", () => {
                this.defaultCursor.element.classList.remove("is-hovered");
            });
        });
    }

    createFollowCursor() {
        const cursor = document.createElement("div");
        cursor.classList.add("followcursor");
        cursor.classList.add("is-active");
        cursor.style.width = "5px";
        cursor.style.height = "5px";
        cursor.style.backgroundColor = "green";
        cursor.style.position = "fixed";
        cursor.style.transitionDelay = "50ms";
        cursor.style.transitionProperty = "all";
        cursor.style.transitionDuration = "100ms";
        cursor.style.zIndex = "999999999";
        // cursor.style.left = parseInt(cursor.style.height) / 2;
        // cursor.style.top = parseInt(cursor.style.height) / 2;

        return {
            element: cursor,
            splitSize: parseInt(cursor.style.height) / 2,
        };
    }

    updateCursorPosition() {
        this.cursor.style.top = `${this.mousePosition.y}px`;
        this.cursor.style.left = `${this.mousePosition.x}px`;

        this.innerCursor.style.top = `${this.mousePosition.y}px`;
        this.innerCursor.style.left = `${this.mousePosition.x}px`;

        requestAnimationFrame(() => this.updateCursorPosition);
    }

    observeMousePosition(cb) {
        window.addEventListener(
            "mousemove",
            (event) => {
                this.mousePosition.x = event.x;
                this.mousePosition.y = event.y;

                cb();
            },
            { signal: this.controller.signal }
        );
    }
}

if (!document.body.classList.contains("wp-admin")) {
    const curseur = new Curseur({
        color: "#f28b4c",
        size: "8px",
        removeCursor: true,
        makeElementsInvisible: [".btn--video"],
    });

    curseur.addHoverClass(
        "a",
        "button",
        ".hamburger-menu",
        ".accordion__header"
    );

    document.addEventListener("facetwp-loaded", function () {
        curseur.addHoverClass(".facetwp-radio");
    });
}
