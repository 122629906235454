class headerObserver {
    constructor(observeEl) {
        if (observeEl) {
            this.observeEl = observeEl;
            this.options = {};
            this.startObserve();
        } else {
            return;
        }
    }

    startObserve() {
        const observeEl = this.observeEl;

        let observer = new IntersectionObserver((entries) => {
            if (entries[0].boundingClientRect.y < 0) {
                observeEl.classList.add("main-header--active");
                // observeEl.setAttribute('data-bs-theme', 'light');
            } else {
                observeEl.classList.remove("main-header--active");
                // observeEl.setAttribute('data-bs-theme', localStorage.getItem('header-theme'));
            }
            // }
        });
        observer.observe(document.querySelector("#pixel-to-watch"));
    }

    remove() {
        observer.unobserve(document.querySelector("#pixel-to-watch"));
        observeEl.classList.remove("main-header--active");
    }
    add() {
        startObserve();
    }
}

window.addEventListener("DOMContentLoaded", function () {
    const menuObserver = new headerObserver(
        document.querySelector("[data-object-observe]")
    );
});
