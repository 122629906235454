import gsap from "gsap";
import { GSDevTools } from "gsap/dist/GSDevTools";
import { CustomEase } from "gsap/dist/CustomEase";
gsap.registerPlugin(GSDevTools, CustomEase);

export function animateBackground() {
    const tl = gsap.timeline();

    const left = document.querySelectorAll(
        '[data-animation-background="left"]'
    );
    const right = document.querySelectorAll(
        '[data-animation-background="right"]'
    );

    if (!left || !right) {
        return;
    }

    tl.fromTo(left, { yPercent: -100 }, { yPercent: 0 });
    tl.fromTo(right, { yPercent: -100 }, { yPercent: 0 }, ">-0.3");

    return tl;
}

export function menuItemOpacity(
    menuItems,
    activeItem = {
        item: null,
    },
    timeline = gsap.timeline({ paused: true })
) {
    menuItems = menuItems.filter((item) => {
        return item !== activeItem.item;
    });

    timeline.fromTo(
        menuItems,
        { opacity: 1 },
        {
            opacity: 0.2,
            stagger: {
                from: "center",
                ease: "power3.inOut",
                amount: 0.1,
            },
        }
    );

    return timeline;
}

export function menuAnimation(options, timeline = gsap.timeline()) {
    let container = document.querySelector(options.container);

    if (!container) {
        return;
    }

    let items = Array.from(container.querySelectorAll(options.el));

    timeline.fromTo(
        items,
        { yPercent: 100 },
        {
            yPercent: 0,
            stagger: {
                amount: 1,
            },
        }
    );

    return timeline;
}

export function marquee(menuItem, timeline = gsap.timeline({ paused: true })) {
    const marqueeContainer = menuItem.item.querySelector(".marquee");
    const marqueeInner = menuItem.item.querySelector(".marquee__inner");
    const marqueeItems = menuItem.item.querySelectorAll(".marquee__part");
    const link = menuItem.item.querySelector(".menu__item-title");

    timeline.fromTo(
        link,
        { y: 0, display: "block" },
        { y: -100, display: "none", ease: "back.in(1.2)", duration: 0.3 }
    );
    timeline.fromTo(
        marqueeContainer,
        {
            opacity: 0,
            y: 100,
            visibility: "hidden",
            display: "none",
        },
        {
            opacity: 1,
            y: 0,
            visibility: "visible",
            display: "block",
        },
        ">-0.1"
    );

    timeline.to(marqueeItems, {
        color: "#ffd504",
    });

    timeline.to(marqueeItems, {
        xPercent: -100,
        repeat: -1,
        duration: 6,
        ease: "linear",
    });

    return timeline;
}

export function mainMenuHoverAnimation(options) {
    let container = document.querySelector(options.container);
    let menuItems = Array.from(container.querySelectorAll(options.items));
    let activeSubmenuAnimation = null;

    this.removeAnimation = () => {
        if (activeSubmenuAnimation) {
            activeSubmenuAnimation.remove();
        }
    };

    this.reverseAnimation = () => {
        if (activeSubmenuAnimation) {
            activeSubmenuAnimation.reverse();
        }
    };

    let menuItemsObj = menuItems.map((item) => {
        let attribute = item.getAttribute("data-menu-item-hover-index");
        let submenu = `[data-menu-item-group-index="${attribute}"]`;

        return {
            item,
            submenu,
            id: attribute,
        };
    });

    menuItemsObj.forEach((menuItem) => {
        // Menu item animation
        let marqueeAnimation = marquee(
            menuItem,
            gsap.timeline({ paused: true })
        );

        // Submenu animation
        let submenuAnimation = menuAnimation(
            {
                container: menuItem.submenu,
                el: "[data-animation-submenu-text]",
                setHeight: true,
            },

            gsap.timeline({ paused: true })
        );

        // Remove active state
        menuItem.remove = function () {
            if (submenuAnimation) {
                submenuAnimation.reverse(0.2);
            }

            if (marqueeAnimation) {
                marqueeAnimation.pause(0);
            }
        };

        menuItem.reverse = function () {
            if (submenuAnimation) {
                submenuAnimation.reverse(1);
            }

            if (marqueeAnimation) {
                marqueeAnimation.pause(0);
            }
        };

        menuItem.item.addEventListener("mouseenter", () => {
            onEnter(submenuAnimation, marqueeAnimation, menuItem);
        });
        menuItem.item.addEventListener("mouseleave", () => {
            // const container = document.querySelector(menuItem.submenu);
            // onLeave(submenuAnimation, marqueeAnimation, menuItem);
        });
    });

    function onEnter(submenuAnimation, marqueeAnimation, menuItem) {
        if (activeSubmenuAnimation !== menuItem && activeSubmenuAnimation) {
            // let container = document.querySelector(
            //     activeSubmenuAnimation.submenu
            // );
            // if (container) {
            //     container.classList.remove("is-active");
            // }
            // activeSubmenuAnimation.remove();
        }

        // Set active menu item
        activeSubmenuAnimation = menuItem;
        let container = document.querySelector(menuItem.submenu);
        if (container) {
            // Add menuHeight
            if (container.parentNode) {
                container.parentNode.style.height = `${container.offsetHeight}px`;
            }

            container.classList.add("is-active");
        }

        if (submenuAnimation) {
            submenuAnimation.play();
        }

        if (marqueeAnimation) {
            marqueeAnimation.play();
        }
    }

    // function onLeave(submenuAnimation, marqueeAnimation, menuItem) {
    // menuItem.submenu.classList.remove("is-active");
    // let opacityAnimation = menuItemOpacity(menuItems, menuItem);
    // opacityAnimation.reverse();
    // menuItem.remove();
    // if (activeSubmenuAnimation !== menuItem.submenu) {
    //     console.log("leave");
    //     submenuAnimation.reverse();
    //     marqueeAnimation.pause(0);
    // }
    // }
}
