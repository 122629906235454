/**
 * A class for observing the visibility of elements and toggling a class on a hamburger menu icon based on visibility and background color darkness.
 */
export default class HamburgerObserver {
    /**
     * Constructs a new HamburgerObserver instance.
     * @param {string} hamburgerElement - Selector for the hamburger menu icon element.
     * @param {string} toggleClass - Class to toggle on the hamburger menu icon.
     */
    constructor(
        hamburgerElement = "[data-menu-icon-mobile]",
        toggleClass = "is-reversed"
    ) {
        this.hamburgerElement = document.querySelector(hamburgerElement);
        this.toggleClass = toggleClass;
        this.isIntersecting = false;
        this.scrolledDown = true;

        // If the hamburger element is not found, return early
        if (!this.hamburgerElement) {
            return;
        }

        // Create IntersectionObservers for scrolling down and up
        this.observerDown = this.createObserver(this.scrolledDownCb, {
            threshold: 0.1,
        });
        this.observerUp = this.createObserver(this.scrolledUpCb, {
            threshold: 0,
            rootMargin: "-87% 0px 0px 0px",
        });
    }

    /**
     * Creates a new IntersectionObserver instance.
     * @param {function} cb - Callback function for the observer.
     * @param {object} options - Options for the observer.
     * @returns {IntersectionObserver} - The created IntersectionObserver instance.
     */
    createObserver(cb, options) {
        let observer = new IntersectionObserver(cb.bind(this), options);
        return observer;
    }

    /**
     * Checks if the page is scrolled up or down.
     * @returns {boolean} - True if scrolled down, false if scrolled up.
     */
    scrolledUpOrDown() {
        let latestScrollPosition = window.scrollY;

        const scrollHandler = () => {
            let isScrolledDown = window.scrollY > latestScrollPosition;
            latestScrollPosition = window.scrollY;
            this.scrolledDown = isScrolledDown ? true : false;
        };

        document.addEventListener("scroll", scrollHandler);

        return this.scrolledDown;
    }

    /**
     * Observes the visibility of elements.
     * @param {string} entries - Selector for elements to observe.
     */
    observe(entries) {
        const elementsToWatch = document.querySelectorAll(entries);
        if (!elementsToWatch) {
            return;
        }

        for (const target of elementsToWatch) {
            this.observerDown.observe(target);
            this.observerUp.observe(target);
        }
    }

    /**
     * Callback function for scrolling down IntersectionObserver.
     * @param {Array<IntersectionObserverEntry>} entries - The intersection entries.
     * @param {IntersectionObserver} observer - The IntersectionObserver instance.
     */
    scrolledDownCb(entries, observer) {
        if (!entries || !this.scrolledUpOrDown()) {
            return;
        }

        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (this.bgIsDark(entry.target)) {
                    this.toggle().add();
                } else {
                    this.toggle().remove();
                }
            }
        });
    }

    /**
     * Callback function for scrolling up IntersectionObserver.
     * @param {Array<IntersectionObserverEntry>} entries - The intersection entries.
     * @param {IntersectionObserver} observer - The IntersectionObserver instance.
     */
    scrolledUpCb(entries, observer) {
        if (this.scrolledUpOrDown()) {
            return;
        }

        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                if (this.bgIsDark(entry.target)) {
                    this.toggle().add();
                } else {
                    this.toggle().remove();
                }
            }
        });
    }

    /**
     * Checks if the background of an element is dark.
     * @param {HTMLElement} element - The element to check.
     * @returns {boolean} - True if the background is dark, false otherwise.
     */
    bgIsDark(element) {
        const computedStyles = window.getComputedStyle(element);
        const backgroundColor = computedStyles.backgroundColor;
        const hsl = this.RGBToHSL(backgroundColor);

        return hsl?.l < 30 ? true : false;
    }

    /**
     * Converts an RGB color string to HSL.
     * @param {string} rgbString - The RGB color string.
     * @returns {object|null} - Object containing HSL values or null if invalid input format.
     */
    RGBToHSL(rgbString) {
        // Extracting the RGB values from the string
        const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/;
        const match = rgbString.match(rgbRegex);
        if (!match) {
            return null; // Invalid input format
        }

        const r = parseInt(match[1]) / 255;
        const g = parseInt(match[2]) / 255;
        const b = parseInt(match[3]) / 255;

        // Calculating the maximum and minimum values among R, G, and B
        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);

        // Calculating the lightness
        const lightness = (max + min) / 2;

        // If min and max are the same, we have a shade of grey
        if (max === min) {
            return { h: 0, s: 0, l: lightness };
        }

        // Calculating the saturation
        const delta = max - min;
        const saturation =
            lightness > 0.5 ? delta / (2 - max - min) : delta / (max + min);

        // Calculating the hue
        let hue;
        switch (max) {
            case r:
                hue = ((g - b) / delta + (g < b ? 6 : 0)) / 6;
                break;
            case g:
                hue = ((b - r) / delta + 2) / 6;
                break;
            case b:
                hue = ((r - g) / delta + 4) / 6;
                break;
        }

        return { h: hue * 360, s: saturation * 100, l: lightness * 100 };
    }

    /**
     * Toggles the class on the hamburger menu icon element.
     * @returns {object} - Object with add and remove methods.
     */
    toggle() {
        return {
            add: () => {
                this.isIntersecting = true;
                this.hamburgerElement.classList.add(this.toggleClass);
            },

            remove: () => {
                this.hamburgerElement.classList.remove(this.toggleClass);
                this.isIntersecting = false;
            },
        };
    }
}
