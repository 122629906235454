// import 'bootstrap';
import AOS from 'aos';

(function () {
    AOS.init({
        once: true,
    });

    document.addEventListener("DOMContentLoaded", () => {
        AOS.refresh();
    });
})();

import { Fancybox } from "@fancyapps/ui";

import GLightbox from "glightbox";
const lightbox = GLightbox({
    touchNavigation: true,
    loop: true,
    autoplayVideos: true,
    openEffect: "zoom",
    closeEffect: "fade",
    cssEfects: {
        // This are some of the animations included, no need to overwrite
        fade: { in: "fadeIn", out: "fadeOut" },
        zoom: { in: "zoomIn", out: "zoomOut" },
    },
});

const header = document.querySelector('.main-header');

if(header) {
    localStorage.setItem("header-theme", header.getAttribute('data-bs-theme'));
}
