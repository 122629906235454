class calculateElementHeight {
    constructor(element, cssVariable) {
        this.element = document.querySelector(element);
        this.root = document.querySelector(":root");
        this.rootStyle = getComputedStyle(this.root);
        this.cssVariable = cssVariable;

        if (!this.element) {
            return;
        }

        this.init();
        this.resize().observe(this.element);
    }

    init() {
        this.setCssVariable({
            cssVariableName: this.cssVariable.cssVariableName,
            value: `${this.element.style.height} px`,
        });
    }

    setCssVariable(customCssVar) {
        const { cssVariableName: key, value } = customCssVar;
        this.root.style.setProperty(key, value);
    }

    resize() {
        return new ResizeObserver((entries) => {
            for (const entry of entries) {
                this.setCssVariable({
                    cssVariableName: this.cssVariable.cssVariableName,
                    value: `${parseInt(
                        entry.contentRect[`${this.cssVariable.getProp}`]
                    )}px`,
                });
            }
        });
    }
}

const heroSummary = new calculateElementHeight("[data-header]", {
    cssVariableName: "--headerHeight",
    getProp: "height",
});
