import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const fsVisuals = [...document.querySelectorAll(('body:not(.wp-admin) .pane--visual-fullscreen'))];
const visuals = [...document.querySelectorAll(('body:not(.wp-admin) .pane--visual:not(.pane--visual-fullscreen)'))];
const blockquotes = [...document.querySelectorAll(('body:not(.wp-admin) blockquote'))];


function strip_tags( _html /*you can put each single tag per argument*/ )
{
    var _tags = [], _tag = "" ;
    for( var _a = 1 ; _a < arguments.length ; _a++ )
    {
        _tag = arguments[_a].replace( /<|>/g, '' ).trim() ;
        if ( arguments[_a].length > 0 ) _tags.push( _tag, "/"+_tag );
    }

    if ( !( typeof _html == "string" ) && !( _html instanceof String ) ) return "" ;
    else if ( _tags.length == 0 ) return _html.replace( /<(\s*\/?)[^>]+>/g, "" ) ;
    else
    {
        var _re = new RegExp( "<(?!("+_tags.join("|")+")\s*\/?)[^>]+>", "g" );
        return _html.replace( _re, '' );
    }
}

// Images parallax
if(fsVisuals) {
    fsVisuals.forEach(fsVisual => {
    const img = fsVisual.querySelector('img');

    const tl = gsap.timeline({
        scrollTrigger: {
        trigger: fsVisual,
        scrub: true,
        pin: false,
        }
    });

    tl.fromTo(img, {
        yPercent: -20,
        ease: 'none'
    }, {
        yPercent: 20,
        ease: 'none'
    });
    });
}

// visual wider
if(visuals) {
    visuals.forEach(visual => {
        if(!visual.classList.contains('pane--visual-fullscreen')) {
            const img = visual.querySelector('img');
            const growTl = gsap.timeline({
                scrollTrigger: {
                trigger: img,
                scrub: true,
                start: "top 98%",
                end: "bottom 100%",
                markers: false,
                }
            });
            growTl.to(img, {
                clipPath:"polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
                duration: 1,
            });
        }
    }); 
}

if(blockquotes) {
    blockquotes.forEach(blockquote => {

        blockquote.innerHTML = strip_tags(blockquote.innerHTML, "b", "strong", "em", "span");
            const blockTl = gsap.timeline({
                scrollTrigger: {
                    start: "top 100%",
                    end: "bottom 50%",
                    scrub: true,
                    trigger: blockquote,
                    markers: false,
                }
            });
            blockTl.to(blockquote, {
                backgroundSize:"100% 100%",
            });
    }); 
}
