function animation(parentSelector, childSelector) {
    const parentElements = document.querySelectorAll(parentSelector);
    const percentage = 100 / 3;

    for (const parentElement of parentElements) {
        let child = childSelector
            ? parentElement.querySelector(childSelector)
            : null;
        if (child) {
            animate(parentElement, child, percentage);
        }
    }
}

function animate(parentElement, elementToAnimate, percentage) {
    let parentElementDimesions = parentElement.getBoundingClientRect();
    parentElement.addEventListener("mousemove", start, false);

    function start(e) {
        let x = Math.round(
            ((e.offsetX - parentElementDimesions.width / 2) * percentage) /
                parentElementDimesions.width
        );
        let y = Math.round(
            ((e.offsetY - parentElementDimesions.height / 2) * percentage) /
                parentElementDimesions.height
        );

        elementToAnimate.style.transform = `translate3d(${x}%, ${y}%, 0)`;
    }

    parentElement.addEventListener("mouseout", () => {
        elementToAnimate.style.transform = null;
    });
}

animation(".card--case-slim", ".card__image");
